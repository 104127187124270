import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Typography,
  Menu,
  Container,
  MenuItem,
} from '@material-ui/core';
import {
  MoreVert as MoreIcon,
  AccountCircle,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';

const styles = (theme) => ({
  // Add your custom styles here if needed
  container: {
    paddingRight: '200px'
  },
  logo: {
    color: '#737373',
    fontSize: '36px'
  }
});

function Header(props) {
  const { match, classes } = props;
  console.log(match)
  const [anchorBuyEl, setAnchorBuy] = useState(null);
  const openBuy = Boolean(anchorBuyEl);
  const navigate = useNavigate();

  const handleBuyClick = (event) => {
    setAnchorBuy(event.currentTarget);
  };

  const handleBuyClose = () => {
    setAnchorBuy(null);
  };

  const [anchorSellEl, setAnchorSell] = useState(null);
  const openSell = Boolean(anchorSellEl);

  const handleSellClick = (event) => {
    setAnchorSell(event.currentTarget);
  };

  const handleSellClose = () => {
    setAnchorSell(null);
  };

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Logo /> */}
          <Typography className={classes.logo}>LegalSutra</Typography>
          {/* Add your menu or other components here */}
          <Grid container className={classes.container} justifyContent="flex-end" alignItems="flex-start" direction="row" spacing={2}>
            
          <Grid item>
            <Button
                id="buy-button"
                aria-controls={openBuy ? 'buy-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openBuy ? 'true' : undefined}
                onClick={() => navigate('/aboutus') }
              >
                <Typography> About Us</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="buy-button"
                aria-controls={openBuy ? 'buy-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openBuy ? 'true' : undefined}
                onClick={handleBuyClick}
              >
                <Typography>Join Us</Typography>
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                id="buy-button"
                aria-controls={openBuy ? 'buy-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openBuy ? 'true' : undefined}
                onClick={handleBuyClick}
              >
                <Typography>Career </Typography>
              </Button>
            </Grid> */}
            
            <Grid item>
              <Button
                id="sell-button"
                aria-controls={openSell ? 'sell-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSell ? 'true' : undefined}
                onClick={handleSellClick}
              >
                <Typography>Contact Us</Typography>
              </Button>
            </Grid>
          </Grid>
          <Menu
            id="buy-menu"
            aria-labelledby="buy-button"
            anchorEl={anchorBuyEl}
            open={openBuy}
            onClose={handleBuyClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleBuyClose}>Profile</MenuItem>
            <MenuItem onClick={handleBuyClose}>My account</MenuItem>
            <MenuItem onClick={handleBuyClose}>Logout</MenuItem>
          </Menu>
          <Menu
            id="sell-menu"
            aria-labelledby="sell-button"
            anchorEl={anchorSellEl}
            open={openSell}
            onClose={handleSellClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleBuyClose}>Sell item</MenuItem>
            <MenuItem onClick={handleBuyClose}>Sell Item 2</MenuItem>
            <MenuItem onClick={handleBuyClose}>Contact</MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default withStyles(styles)(Header);
