import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from "../../assets/logo.svg";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ReactComponent as RightArrowSVG } from "../../assets/mdi_code-greater-than.svg"
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Typography,
  Divider,
  Box,
  Menu,
  Container,
  MenuItem,
  Paper,
} from '@material-ui/core';

import {
  MoreVert as MoreIcon,
  AccountCircle,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
// import ContractManagemenrThumbnail from '../../assets/contractmanagement_thumbnail.png';
import RightArrow from '../../assets/mdi_code-greater-than.png';


const styles = (theme) => ({
  // Add your custom styles here if needed
  paper: {
    boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.25)"
  },

  container: {
    marginTop: "50px",

  },
  heading: {
    marginTop: "50px",
    // width: "446px",
    height: "69px",
    flexShrink: 0
  },
  headingTypography : {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  card: {
    width: "430px",
    height: "775px",
    flexShrink: 0,
  },
  cardMedia: {
    width: "430px",
    height: "301.882px",
    flexShrink: 0
  },
  cardContent: {
    margin: "16px"

  },

  cardTitle: {
    width: "345px",
    height: "100px",

  },
  cardTitleTypography: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  cardDescription: {
    width: "369px",
    height: "300px",
    flexShrink: 0,


  },
  cardDescriptionTypography: {
    overflow: "hidden",
    color: "#000",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  cardActions: {
    alignContent: "flex-end",
    justifyContent: "flex-end"
  },
  rightArrow: {
    width: "50px",
    height: "50px",
    flexShrink: 0
  }


});

function HomeCards(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const cards = [{
    "title": "Contract Management",
    "description": "We specialize in Contract Management, offering end-to-end support to streamline the entire contract lifecycle. Our services include drafting, reviewing, and negotiating contracts to ensure they align with your business objectives and legal requirements. We handle contract organization, storage, and tracking, making it easy to manage renewals, amendments, and compliance. With our expertise, you can mitigate risks, optimize contract performance, and ensure that all agreements are executed efficiently and accurately, giving you confidence in your contractual obligations and partnerships.",
    "path": "contract-management",
    "img":require("../../assets/contract_management_thumbnail.png")
  }, {
    "title": "Litigation and Investigation Support",
    "description": "We provide comprehensive Litigation and Investigation Support, assisting legal teams in every aspect of case preparation and execution. Our services include managing and analyzing extensive documents, developing strategic case plans, and ensuring seamless trial preparation with technical support. We also conduct thorough investigations, including forensic accounting and digital forensics, to uncover critical evidence and ensure compliance, empowering your legal team to build and present a compelling case in court.",
    "path": "litigation-investigation-support",
    "img":require("../../assets/litigationandinvestigationsupport.png")
  }, {
    "title": "Legal Research and Analytics",
    "description": "We excel in Legal Research and Analytics, providing the insights you need to navigate complex legal challenges. Our services include in-depth research on case law, statutes, and regulations to support your legal arguments and strategies. We analyze legal trends, precedents, and data to identify patterns and opportunities that can give your case a strategic edge. With our comprehensive research and advanced analytics, we help you make informed decisions, anticipate legal risks, and craft compelling, evidence-based arguments that strengthen your position in any legal matter.",
    "path": "legal-research-and-analytics",
    "img":require("../../assets/legalresearchandanlysis.png")
  },
  ]
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
      <Grid item className={classes.heading}>
        <Typography className={classes.headingTypography}>What we do</Typography>
      </Grid>
      <Divider variant='middle' component="div" style={{
        height: "3px",
        width : "764.003px",
        background : "#D60056"
      }}/>
      <Grid item className={classes.description}>
        <Typography className={classes.descriptionTypography}>Explore how our wide range of legal services, including contract management, litigation support, and legal research, are designed to meet your specific needs and drive successful outcomes in complex situations</Typography>
      </Grid>
      <Grid container justifyContent="center" spacing={2} className={classes.container}>
      {
        cards.map((card,i) => {
          return (
            <React.Fragment key={i}>
              <Grid item key={i}>
                <Paper className={classes.paper}  >
                  <Card elevation={0} className={classes.card} >
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      title="green iguana"
                    />
                    <CardContent className={classes.cardContent}>
                      <Grid container spacing={2} >
                        <Grid item className={classes.cardTitle}>
                          <Typography className={classes.cardTitleTypography}>
                            {card.title}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.cardDescription}>
                          <Typography className={classes.cardDescriptionTypography}>
                          {card.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {/* <CardActions sx={{ justifyContent: "flex-end" }}>
                      <IconButton color="primary" aria-label="add to shopping cart">
                        <RightArrowSVG onClick={() => navigate(card.path) }/>
                      </IconButton>
                    </CardActions> */}
                  </Card>
                </Paper>
              </Grid>
            </React.Fragment>
          )
        })
      }
    </Grid>
    </Grid> 
  )
  }
export default withStyles(styles)(HomeCards);
