import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Chip, Divider, Avatar, Grid, Paper, TextField, IconButton, Typography, Card, CardActions, CardContent, Button } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { withStyles } from '@material-ui/core/styles';
import BannerImage from '../../../assets/marital-disputes.png'; // Import using relative path


const styles = (theme) => ({

  background: {
    backgroundColor: "#435876",
    width: '100vw',
    height: "50vh",
  },
  container: {
    width: 'min-content',
    transform: 'translate(10vw, -35vh)', // Center it properly
    [theme.breakpoints.down('sm')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      height: '100vh', // Set height to 50vh (50% of viewport height)
      // width: '77vw', // Set width to 100vw (full viewport width)
      transform: "translate(10vw, -35vh)",
    },
    [theme.breakpoints.down('md')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      height: '100vh', // Set height to 50vh (50% of viewport height)
      // width: '77vw', // Set width to 100vw (full viewport width)
      transform: 'translate(20vw, -35vh)'
    },
  },
  breadcrumbs: {
    fontSize: '16px',
    color: '#ffffff',
    position: 'relative', // Position the new container absolutely
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  bannerTitle: {
    fontSize: '48px',
    color: '#ffffff',
    position: 'relative', // Position the new container absolutely
  },
  heading: {
    fontSize: '30px',
    position: 'relative'
  },
  body: {
    fontSize: '16px',
    color: '#000',
    position: 'relative', // Position the new container absolutely
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  image: {
    width: '80vw',
    height: '20vw',
    [theme.breakpoints.down('sm')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      height: '20vw',
    },
    [theme.breakpoints.down('md')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      height: '20vw',
    }
  }

});

function NestedPage(props) {
  const { pathname } = useLocation();
  console.log("pathname", pathname)
  // const classes = useStyles();
  const { classes } = props

  const [value, setValue] = React.useState('1');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const pages = [
    {
      "title": "Criminal Case",
      "heading": "Our Recent Case Studies",
      "body": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      "path": "/criminal-case",
      "img":require("../../../assets/criminalcase_thumbnail.png")
    }, {
      
      "title": "Marital Disputes",
      "heading": "Our Recent Case Studies",
      "body": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      "path": "/marital-disputes",
      "img":require("../../../assets/marital-disputes.png")
    }, {
      
      "title": "Contract Management",
      "heading": "Our Recent Case Studies",
      "body": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      "path": "/contract-management",
      "img":require("../../../assets/contract_management.png")
      
    },{
      "title": "Litigation And Investigation Support",
      "heading": "Our Recent Case Studies",
      "body": "We combine the latest technology with our proprietary processes to deliver cost-effective eDiscovery and managed review solutions.                ",
      "path": "/litigation-investigation-support",
      "img":require("../../../assets/litigation-and-investigation-support.png")
      
    },{
      "title": "Legal Research and Analytics",
      "heading": "Our Recent Case Studies",
      "body": "We ensure your business continually meets the needs of existing and forthcoming laws and regulations by automating manual processes to improve the accuracy and interpretation of legislative documentation across multi-jurisdictional and language variants.",
      "path": "/legal-research-and-analytics",
      "img":require("../../../assets/legal-research-and-analysis.png")
      
    },{
      "title": "Who We Are",
      "heading": "About Us",
      "body": "At LegalSutra, we are a dedicated team of legal professionals committed to providing comprehensive and strategic legal services tailored to meet the unique needs of our clients. Specializing in Litigation and Investigation Support, Contract Management, and Legal Research and Analytics, we leverage our extensive expertise and cutting-edge technology to deliver results that protect your interests and drive your success. Our approach is rooted in a deep understanding of the law, combined with practical experience across various industries. We pride ourselves on being more than just legal advisors—we are your partners in navigating complex legal landscapes. Whether you need robust support for a litigation process, meticulous contract management, or insightful legal research and analytics, our team is here to provide clear, actionable solutions that empower you to achieve your objectives. We are committed to excellence, integrity, and client satisfaction, ensuring that every case, contract, and legal issue is handled with the utmost care and professionalism. At [LegalSutra, we don't just solve problems; we create opportunities and safeguard your future.",
      "path": "/aboutus",
      "img":require("../../../assets/who-we-are.png")
      
    }]
  return (
    <React.Fragment>
      <Grid container>
        <Grid item className={classes.background}></Grid>
      </Grid>
      <Grid container direction="column" spacing={2} className={classes.container} >

        {pages.filter((page, index) => page.path == pathname)
          .map(page => {
            return (
              <React.Fragment>
                <Grid item>
                  <Breadcrumbs sx={{
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }} className={classes.breadcrumbs} aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      href="/casestudies"
                    >
                      Case Studies
                    </Link>
                    <Typography color="primary">{page.title}</Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item>
                  <Typography className={classes.bannerTitle} sx={{ fontSize: 14 }} gutterBottom>
                    {page.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Paper elevation={0}>
                    <img src={page.img} className={classes.image}/>
                  </Paper>
                </Grid>
                <Grid item>
                  <Typography className={classes.heading}>{page.heading}</Typography>
                </Grid>
                <Grid item>
                  <Divider component="div" style={{
                    height: "3px",
                    width: "40vw",
                    background: "#D60056"
                  }} />
                </Grid>
                <Grid item>
                  <Typography className={classes.body} paragraph="true" display="block">
                    {page.body}
                  </Typography>
                </Grid>
              </React.Fragment>
            )
          })}

      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(NestedPage);
