import React from 'react';
import { useLocation } from 'react-router-dom';
import { Chip, Divider, Avatar, Grid, Paper, TextField, IconButton, Typography, Card, CardActions, CardContent, Button } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import BannerImage from '../../../assets/banner.png'; // Import using relative path
import { HomeCards, RecentCaseStudies } from '../../layouts';
import CookieConsent from "react-cookie-consent";

// const useStyles = makeStyles(theme => ({
//   // Add your custom styles here
//   paperContainer: {
//     backgroundImage: `url(${BannerImage})`
//   }
// }));

const styles = (theme) => ({
  // Add your custom styles here
  paperContainer: {
    backgroundImage: `url(${BannerImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    lightGray: '50%',
    // display: 'flex',            // Use flex container to overlay new Paper
    height: '100vh', // Set initial height to 100vh (full viewport height)
    width: '100vw', // Set initial width to 100vw (full viewport width)
    [theme.breakpoints.down('sm')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      height: '50vh', // Set height to 50vh (50% of viewport height)
      width: '100vw', // Set width to 100vw (full viewport width)
    },
    [theme.breakpoints.up('md')]: { // Apply styles for screens greater than or equal to 960px (md breakpoint)
      height: '70vh', // Set height to 80vh (80% of viewport height)
      width: '100vw', // Set width to 80vw (80% of viewport width)
    },
  },
  bannerTitle: {
    padding: theme.spacing(3),
    // backgroundColor: '#FFF3E3',
    // borderRadius: '10px',
    fontSize: '40px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#ffffff',
    position: 'absolute', // Position the new container absolutely
    top: '60%', // Move it to the vertical center
    left: '20%', // Move it to the horizontal center
    transform: 'translate(-20%, -60%)', // Center it properly
    [theme.breakpoints.down('sm')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      // height: '50vh', // Set height to 50vh (50% of viewport height)
      width: '20vw', // Set width to 100vw (full viewport width)
    },
    [theme.breakpoints.up('md')]: { // Apply styles for screens greater than or equal to 960px (md breakpoint)
      // height: '80vh', // Set height to 80vh (80% of viewport height)
      width: '40vw', // Set width to 80vw (80% of viewport width)
    },
  },
  bannerSubTitle: {
    padding: theme.spacing(3),
    // backgroundColor: '#FFF3E3',
    // borderRadius: '10px',
    fontSize: '20px',
    // fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#ffffff',
    position: 'absolute', // Position the new container absolutely
    top: '80%', // Move it to the vertical center
    left: '20%', // Move it to the horizontal center
    transform: 'translate(-20%, -80%)', // Center it properly
    [theme.breakpoints.down('sm')]: { // Apply styles for screens smaller than or equal to 600px (sm breakpoint)
      // height: '50vh', // Set height to 50vh (50% of viewport height)
      width: '20vw', // Set width to 100vw (full viewport width)
    },
    [theme.breakpoints.up('md')]: { // Apply styles for screens greater than or equal to 960px (md breakpoint)
      // height: '80vh', // Set height to 80vh (80% of viewport height)
      width: '40vw', // Set width to 80vw (80% of viewport width)
    },
  }
});

function Home(props) {
  const location = useLocation(); // Add the useLocation hook
  const currentPathname = location.pathname;

  // const classes = useStyles();
  const { classes } = props

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>

      <Grid container spacing={2}>
        {/* Use classes.paperContainer to apply custom styles */}
        <Grid item>
          {/* <CookieConsent
            location="top"
            buttonText="Sure man!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B", zIndex: 99999 }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={999}

          >
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
          </CookieConsent> */}
        </Grid>
        <Grid item>
          <Paper className={classes.paperContainer}>
            <Typography className={classes.bannerTitle} sx={{ fontSize: 18 }} gutterBottom>
              Contract and Litigation Services in India
            </Typography>
            <Typography className={classes.bannerSubTitle} sx={{ fontSize: 10 }} gutterBottom>
              Trusted advisors for decades
            </Typography>
          </Paper>

        </Grid>

        <HomeCards />
        <RecentCaseStudies />
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(Home);
