import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function RouteWithLayout(props) {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Routes>
      <Route
        {...rest}
        element={
          <Layout>
            <Component />
          </Layout>
        }
      />
      {/* Catch-all route to handle unmatched paths */}
      <Route path="*" element={<Layout><Component /></Layout>} />
    </Routes>
  );
}

RouteWithLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType.isRequired,
  path: PropTypes.string
};
