import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';

import profileReducer from './profileReducer';
// import projectReducer from './projectReducer';
// import configReducer from './configReducer';

export default combineReducers({
  profile: profileReducer,
//   routerData: routerReducer,
  //   configData: configReducer,

//   projects: projectReducer
});
