// profileReducer.js
const initialState = {
    // initial state for the profile slice
  };
  
const profileReducer = (state = initialState, action) => {
    // reducer logic based on action types
    return state;
};
  
export default profileReducer
