import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from "../../assets/logo.svg";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ReactComponent as RightArrowSVG } from "../../assets/mdi_code-greater-than.svg"
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Typography,
  Box,
  Menu,
  Container,
  MenuItem,
  Paper,
} from '@material-ui/core';

import {
  MoreVert as MoreIcon,
  AccountCircle,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
// import ContractManagemenrThumbnail from '../../assets/contractmanagement_thumbnail.png';
import LeftArrow from '../../assets/mdi_code-greater-than.png';
import RightArrow from '../../assets/mdi_code-greater-than.png';
const styles = (theme) => ({
  // Add your custom styles here if needed
  paper: {
    boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.25)"
  },

  container: {
    marginTop: "50px",
    width: "80vw",
    overflow: "auto",


  },
  card: {
    width: "300px",
    // height: "500px",
    flexShrink: 0,
  },
  cardMedia: {
    width: "300px",
    height: "227px",
    flexShrink: 0
  },
  cardContent: {
    // margin: "16px"

  },

  cardTitle: {
    width: "345px",
    height: "50px",

  },
  cardTitleTypography: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  cardDescription: {
    width: "fit-content",
    // height: "146.628px",
    flexShrink: 0,


  },
  cardDescriptionTypography: {
    overflow: "hidden",
    color: "#000",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  cardActions: {
    alignContent: "flex-end",
    justifyContent: "flex-end"
  },
  rightArrow: {
    width: "50px",
    height: "50px",
    flexShrink: 0
  },
  arrow:{
    padding: theme.spacing(1),
    
  }


});

function CaseStudyCards(props) {
  const classes = props.classes;
  const navigate = useNavigate();
  const cards = props.cards

  return (
    <React.Fragment >
      <Box display="flex" alignItems="center" className={classes.box} spacing={4}>
        <Grid item xs="auto" className={classes.arrow}>
          <RightArrowSVG />
        </Grid>
        <Grid item>
          <Grid container wrap='nowrap' justifyContent="center" spacing={4} className={classes.container}>
            {cards.map((card, i) => {
              return (
                <React.Fragment key={i}>
                  <Grid item key={i} >
                    <Paper className={classes.paper} spacing={2}>
                      <Card elevation={0} className={classes.card} >
                        <CardMedia
                          className={classes.cardMedia}
                          image={card.img}
                          title="green iguana"
                        />
                        <CardContent className={classes.cardContent}>
                          <Grid container spacing={1}>
                            <Grid item className={classes.cardTitle}>
                              <Typography className={classes.cardTitleTypography}>
                                {card.cardTitle}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.cardDescription}>
                              <Typography className={classes.cardDescriptionTypography} >
                                {card.cardDescription}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "flex-end" }}>
                          <IconButton color="primary" aria-label="add to shopping cart">
                            <RightArrowSVG onClick={() => navigate(card.path) }/>
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Paper>
                  </Grid>

                </React.Fragment>
              )
            })
            }
          </Grid>
        </Grid>
        <Grid item xs="auto" className={classes.arrow}>
          <RightArrowSVG />
        </Grid>
      </Box>
    </React.Fragment>
  );
}

CaseStudyCards.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  ).isRequired
};
export default withStyles(styles)(CaseStudyCards);
