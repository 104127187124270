import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Main, RouteWithLayout } from './layouts';
import { Home, NestedPage } from './views';
import { CssBaseline } from '@mui/material';
import ReactDOM from 'react-dom'; // Make sure to import ReactDOM

export default class App extends Component {

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
      
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<RouteWithLayout component={Home} layout={Main} />} />
            <Route path="/aboutus" element={<RouteWithLayout component={NestedPage} layout={Main} />} />
            <Route path="/contract-management" element={<RouteWithLayout component={NestedPage} layout={Main} />} />
            <Route path="/marital-disputes" element={<RouteWithLayout component={NestedPage} layout={Main} />} />
            <Route path="/litigation-investigation-support" element={<RouteWithLayout component={NestedPage} layout={Main} />} />
            <Route path="/legal-research-and-analytics" element={<RouteWithLayout component={NestedPage} layout={Main} />} />
            <Route path="/criminal-case" element={<RouteWithLayout component={NestedPage} layout={Main} />} />
            {/* <Route path="/hom" element={<RouteWithLayout component={Home} layout={Main} />} /> */}
            
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}