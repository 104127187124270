// import React, { useState } from 'react';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from "../../assets/logo.svg";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ReactComponent as RightArrowSVG } from "../../assets/mdi_code-greater-than.svg"
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Typography,
  Divider,
  Box,
  Menu,
  Container,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@mui/lab';

import {
  MoreVert as MoreIcon,
  AccountCircle,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
// import ContractManagemenrThumbnail from '../../assets/contractmanagement_thumbnail.png'; 
import RightArrow from '../../assets/mdi_code-greater-than.png';
import CaseStudyCards from './CaseStudyCards';
const styles = (theme) => ({
  // Add your custom styles here if needed
  // paper :{
  //   boxShadow :"0px 4px 50px 0px rgba(0, 0, 0, 0.25)"
  // },

  // container : {
  //   marginTop: "100px",

  // },
  // card :{
  //   width: "430px",
  //   height: "675px",
  //   flexShrink: 0,
  // },
  // cardMedia:{
  //   width: "430px",
  //   height: "301.882px",
  //   flexShrink: 0
  // },
  // cardContent : {
  //   margin : "16px"

  // },

  // cardTitle :{
  //   width : "345px",
  //   height: "100px",

  // },
  heading: {
    marginTop: "50px",
    width: "446px",
    height: "69px",
    flexShrink: 0
  },
  headingTypography: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  description: {
    height: "33px",
    flexShrink: 0,
  },
  descriptionTypography: {
    overflow: "hidden",
    color: "#000",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145.385%"
  },
  tabPanel: {
    // width: "60vw",
    // overflow: "auto",
    // whiteSpace: 'nowrap'
  }
  // cardActions : {
  //   alignContent : "flex-end",
  //   justifyContent: "flex-end"
  // },
  // rightArrow :{
  //   width: "50px",
  //   height: "50px",
  //   flexShrink: 0
  // }


});

function RecentCaseStudies(props) {
  const { classes } = props;
  const [value, setValue] = React.useState('0');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const cards = {
    "0": [{
      "img": require("../../assets/criminalcase_thumbnail.png"),
      "cardTitle": "Cheating and Forgery",
      "cardDescription": "We relieve the pressure on internal resources by overseeing the negotiation and delivery of high-volume contracts through efficient contract lifecycle management that reduces complexity and cost.",
      "path":"/criminal-case"
    }, {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Rape",
      "cardDescription": "We relieve the pressure on internal resources by overseeing the negotiation and delivery of high-volume contracts through efficient contract lifecycle management that reduces complexity and cost.",
      "path":"/marital-disputes"
    }],
    "1": [{
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Civil Litigation",
      "cardDescription": "In Civil Litigation, we represent clients in disputes over contracts, property, and personal injury. We guide you through every step, from case filing to resolution, aiming for the best possible outcome through settlement or trial.",
      "path":"/civil-litigation"
    },
    {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Partition Suites",
      "cardDescription": "We relieve the pressure on internal resources by overseeing the negotiation and delivery of high-volume contracts through efficient contract lifecycle management that reduces complexity and cost.",
      "path":"/marital-disputes"
    },
    {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Declaratory Suites",
      "cardDescription": "We relieve the pressure on internal resources by overseeing the negotiation and delivery of high-volume contracts through efficient contract lifecycle management that reduces complexity and cost.",
      "path":"/declaratory-suite-case"
    },
    {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Execution Suites",
      "cardDescription": "We relieve the pressure on internal resources by overseeing the negotiation and delivery of high-volume contracts through efficient contract lifecycle management that reduces complexity and cost.",
      "path":"/execution-suites-case"
    }],
    "2": [{
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Matrimonial",
      "cardDescription": "In Marital Disputes, we assist clients with issues related to divorce, custody, and property division. We provide expert guidance and support to navigate the legal complexities and work towards fair and effective resolutions.",
      "path":"/matrimonial-case"
    },
    {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Succession",
      "cardDescription": "Discover how our team navigates succession planning with precision, offering comprehensive services for estate administration, will execution, and resolving inheritance disputes to secure your legacy.",
      "path":"/succession-case"
    },
    {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Testamatry Cases",
      "cardDescription": "We offer expert legal guidance in testamentary cases, helping you navigate issues related to wills and estates, ensuring your rights are protected and resolutions are achieved efficiently.",
      "path":"/testamatry-case"
    },
    {
      "img": require("../../assets/marital-disputes.png"),
      "cardTitle": "Bigamy",
      "cardDescription": "Our firm provides expert legal support in handling bigamy cases, guiding you through the complexities of legal proceedings, ensuring fair outcomes, and addressing the unique challenges involved.",
      "path":"/bigamy-case"
    }],
    "3": [{
      "img": require("../../assets/criminalcase_thumbnail.png"),
      "cardTitle": "Business Law",
      "cardDescription": "We provide comprehensive legal support for businesses, covering everything from company formation and contracts to compliance and dispute resolution, ensuring your operations run smoothly and legally.",
      "path": "/business-case"
    }],
    "4":[{
      "img": require("../../assets/criminalcase_thumbnail.png"),
      "cardTitle": "Real Estate Law",
      "cardDescription": "earn about our approach to real estate law, where we offer detailed guidance on property transactions, contract negotiations, and legal issues to help you navigate the complexities of the real estate market effectively.",
      "path":"/realestate-case"
    }],
    "5": [{
      "img": require("../../assets/criminalcase_thumbnail.png"),
      "cardTitle": "Environment Law",
      "cardDescription": "We provide specialized legal support in environmental law, assisting with compliance, regulatory issues, and environmental disputes to ensure your operations align with legal standards and sustainability goals.",
      "path":"/environment-case"
    }]
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >

      <Grid item className={classes.heading}>
        <Typography className={classes.headingTypography}>Our Recent Case Studies</Typography>
      </Grid>
      <Divider variant='middle' component="div" style={{
        height: "3px",
        width: "764.003px",
        background: "#D60056"
      }} />
      <Grid item className={classes.description}>
        <Typography className={classes.descriptionTypography}>Discover how we've tackled various legal challenges and achieved favorable outcomes for our clients.</Typography>
      </Grid>
      <Grid item justifyContent='center'>
        <Grid container direction='column'>

          <TabContext value={value} justifyContent="center">
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              sx={{ margin: "auto" }}
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="0" label="Criminal Law" />
              <Tab value="1" label="Civil Law" />
              <Tab value="2" label="Family Law" />
              <Tab value="3" label="Business Law" />
              <Tab value="4" label="Real Estate Law" />
              <Tab value="5" label="Environment Law" />
              <Tab value="6" label="Banking Law" />
            </Tabs>


            <TabPanel className={classes.tabPanel} value="0">
              <CaseStudyCards cards={cards[0]} />
            </TabPanel>
            <TabPanel value="1"><CaseStudyCards cards={cards[1]} /></TabPanel>
            <TabPanel value="2"><CaseStudyCards cards={cards[2]} /></TabPanel>
            <TabPanel value="3"><CaseStudyCards cards={cards[3]} /></TabPanel>
            <TabPanel value="4"><CaseStudyCards cards={cards[4]} /></TabPanel>
            <TabPanel value="5"><CaseStudyCards cards={cards[5]} /></TabPanel>
          </TabContext>

        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(RecentCaseStudies);
