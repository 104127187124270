import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import App from './components/App';
import { ThemeProvider } from '@material-ui/core/styles';
import { blue, amber } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';


const theme = createTheme({
  palette: {
    primary: {
      light: '#737b7f',
      main: '#FFFFFF',
      dark: '#383e42',
      contrastText: '#fff'
    },
    secondary: {
      // main: amber[800]
      main: "#D60056"
    },
    text: {
      primary: '#737373'
    }
  },
  typography: {
    color: '#737373', // doesn't work,
    fontFamily: 'Montserrat',

    button: {
      fontSize: '16px', // works
      color: '#737373', // doesn't work,
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal'
    }
  }
});

console.log('theme->', theme);

// Enable Concurrent Mode
const rootElement = document.getElementById('root');


render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      
      <App />
    </Provider>
  </ThemeProvider>,
  rootElement
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// root.render(<AppWrapper />);

