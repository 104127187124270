import React from 'react';
import { Typography, Link } from '@material-ui/core';

export default function Copyright() {
    return (
        <Typography  align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                LegalSutra
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}